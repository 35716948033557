/* @import url('https://webfontworld.github.io/SCoreDream/SCoreDream.css');
@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');

* {
  font-family: 'SCoreDream', sans-serif;
  font-family: 'SCoreDream';
  box-sizing: border-box;
  font-weight: 700;
  text-decoration: none;
} */

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css");

* {
  font-family: 'pretendard';
  font-weight: 600;
}

a {
  color: black;
  text-decoration: none;
}

p {
  margin: 0;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  width: 100%;
}

#root {
  width: 100%;
  position: relative;
  min-height: 100vh;
  padding-bottom: 30px;
}

.App {
  width: 100%;
  padding: 20px 15px 40px 15px;
  width: inherit;
  float: left;
}

.App2 {
  float: left;
  padding: 20px 15px;
  width: inherit;
}

.App3 {
  padding: 30px 15px;
  width: inherit;
}
.Cafeteria1 {
  font-size: 14px;
  font-weight: 400;
}

/* @media screen and (min-width: 576px) {
  .App3 {padding: 20px;}
} */